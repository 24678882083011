<template>
  <div class="page-container">
    <div class="page-content">
      <div class="vui-tc" v-if="data.title">
        <h2>{{ data.title }}</h2>
        <p class="vui-g9 vui-f14">发布时间：{{ data.publishTime | dateFormat }}</p>
      </div>
      <ul class="inputs">
        <li v-for="(d, index) in data.pList" :key="index">
          <div class="vui-row vui-align-items-center" v-for="(item, index2) in d.sentenceList" :key="index2">
            <div class="vui-col-auto">
              <Button
                class="btn-rev"
                type="default"
                size="small"
                icon="ios-redo"
                title="还原"
                @click="revocation(item)"
              ></Button>
            </div>
            <div class="vui-col">
              <Input :disabled="uploading" size="large" v-model="item.content" title="" />
            </div>
          </div>
        </li>
      </ul>
      <Button class="btn-submit" size="large" type="warning" :loading="uploading" @click="onSubmit">提交</Button>
    </div>
  </div>
</template>

<script>
import { ApiService } from '../api/apiService';
import {contentExt_updateIeng} from '../api/hbService'

export default {
  data() {
    return {
      data: {
        id: null,
        pList: [],
        publishTime: null,
        title: null
      },
      uploading: false
    };
  },
  created() {
    // 初始化数据
    this.loadData();
  },
  methods: {
    loadData() {
      return ApiService.contentExt_queryIeng({
        id: this.$route.params.id,
        pageNo: 1,
        pageSize: 10
      })
        .then(res => {
          if (res && res.rows && res.rows.length) {
            this.data = res.rows.map(x => {
              x.pList = x.pList.map(f => {
                f.sentenceList = f.sentenceList.map(z => {
                  z.content_bak = z.content;
                  return z;
                });
                return f;
              });
              return x;
            })[0];
          } else {
            this.data = {};
            this.$Message.error('获取数据失败，请重试或联系管理员');
          }
        })
        .catch(err => {
          this.$Message.error(err.msg);
        });
    },
    revocation(item) {
      item.content = item.content_bak;
    },
    onSubmit() {
      this.uploading = true;
      contentExt_updateIeng(this.data)
        .then(() => {
          this.$Message.success('更新成功');
        })
        .catch(err => {
          this.$Message.error(err.msg);
        })
        .finally(() => {
          this.uploading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.page-container {
  background: rgba(242, 242, 242, 1);
}

.page-content {
  width: 950px;
  padding: 20px;
  margin: 30px auto 0;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  min-height: 86vh;
}

.inputs {
  list-style: none;
}

.btn-rev {
  color: orange;
}

.btn-submit {
  position: fixed;
  bottom: 100px;
  right: 10px;
  width: 120px;
}

.invalid-message {
  padding-left: 50px;
}
</style>
