/**
 * apiService
 */

import axios from './newAxiosProxy';

export class ApiServiceStatic {
  contentExt_queryIeng(params) {
    return axios({
      method: 'get',
      url: '/contentExt/queryIeng/',
      params
    });
  }

 

  /**
   * 
   * @param {{id,status}[]} data 
   */
  shelfChange(data) {
    return axios({
      method: 'put',
      url: '/contentExt/upOrDown',
      data
    })
  }

  
  /**
   * 
   * @param {*} params 
   * @param {'waitdeal'|'history'|'published'|'recycle'} type 
   */
  longSentenceList(params, type) {
    let url = {
      waitdeal: '/longSentence/getTaskList',
      history: '/longSentence/getHisTaskList',
      published: '/longSentence/getPublishList',
      recycle: '/longSentence/getDisusedList'
    }[type]
    return axios({
      url,
      method: 'get',
      params
    })
  }
}

export const ApiService = new ApiServiceStatic();
